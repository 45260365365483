import { Controller } from 'react-hook-form';
import Select, { createFilter, components } from 'react-select';
import Search from '../public/images/Search.svg';
import Image from './Image';
import { theme as tailwindTheme } from 'tailwind.config';
const SelectWithImage = ({
    label,
    options,
    control,
    name,
    error,
    setValue,
    onChange,
    withImage = false,
    register,
    customFormatOptionlabel,
    containerClassName = 'flex items-center',
    optionsWidth = 'w-full',
    components,
    controlStyles = { padding: 10 },
    optionBgColor = tailwindTheme?.colors?.primary?.[500],
    isDisabled = false,
    disabledOpacity = 0.5,
    isMulti = false,
    ...inputProps
}) => {
    const filterConfig = {
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom: 'any',
    };
    const customTheme = (theme) => {
        return {
            ...theme,
            colors: {
                ...theme.colors,
                primary: tailwindTheme?.colors?.primary?.[500],
            },
        };
    };
    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            flexGrow: 1,
        }),
        valueContainer: (base) => ({
            ...base,
            display: 'flex',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? optionBgColor : 'white',
            color: state.isSelected ? 'white' : 'black',
        }),
        control: (provided, state) => ({
            ...provided,
            ...controlStyles,
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? disabledOpacity : 1;
            const transition = 'opacity 300ms';
            return { ...provided, opacity, transition };
        },
    };
    const handleOnChange = (data) => onChange(data);

    return (
        <div className="flex flex-col ">
            {label && <label className="mb-2">{label}</label>}
            <Controller
                as={Select}
                name={name}
                control={control}
                rules={{
                    required: {
                        message: 'Item type is required.',
                    },
                }}
                render={({ field: { onChange, value, ref, name } }) => {
                    return (
                        <div
                            className={`relative ${
                                inputProps.isHighLighted &&
                                'border-[3px] border-primary-500 rounded-md'
                            }`}
                        >
                            <Select
                                {...register(name)}
                                theme={customTheme}
                                inputRef={ref}
                                styles={customStyles}
                                closeMenuOnSelect={true}
                                options={options}
                                placeholder={
                                    inputProps.placeholder
                                        ? inputProps.placeholder
                                        : 'Select'
                                }
                                isMulti={isMulti}
                                value={
                                    !inputProps.enableRemove
                                        ? options.find((c) => c.value === value)
                                        : options.find(
                                              (c) => c.value === value
                                          ) || 'Select'
                                }
                                isClearable={
                                    inputProps.isClearable
                                        ? inputProps.isClearable
                                        : false
                                }
                                isDisabled={isDisabled}
                                components={
                                    inputProps.searchImage && {
                                        ValueContainer,
                                    }
                                }
                                onChange={handleOnChange}
                                filterOption={createFilter(filterConfig)}
                                formatOptionLabel={(val) => {
                                    if (customFormatOptionlabel) {
                                        return customFormatOptionlabel(val);
                                    }
                                }}
                                error={error}
                                {...inputProps}
                            />
                            {inputProps.enableRemove && value && (
                                <div
                                    className="absolute top-[21px] right-14"
                                    onClick={
                                        inputProps.handleRemove ??
                                        handleOnChange
                                    }
                                >
                                    <Image
                                        src={'/images/close-hamburger.svg'}
                                        alt={'close'}
                                        className="w-5 h-4 cursor-pointer opacity-30 hover:opacity-100"
                                    />
                                </div>
                            )}
                        </div>
                    );
                }}
            />
            {error && !error?.message && (
                <div className="mt-2 text-left text-semantic-error-base ">{error}</div>
            )}
            {error?.message && (
                <div
                    className={`mt-2 text-left text-semantic-error-base ${inputProps.errorClassName}`}
                >
                    {error.message}
                </div>
            )}
        </div>
    );
};

const ValueContainer = ({ children, ...props }) => {
    return (
        components.ValueContainer && (
            <components.ValueContainer {...props}>
                {!!children && (
                    <Image
                        src={Search.src}
                        className={`w-5 h-5 mr-4 self-center cursor-pointer`}
                    />
                )}
                {children}
            </components.ValueContainer>
        )
    );
};
export default SelectWithImage;
