import { useEffect } from "react";

const Input = ({
    id,
    register,
    type,
    placeholder,
    label,
    labelClass,
    subLabel,
    subLabelClass = 'p5-regular text-gray-500',
    error,
    onClick,
    onPaste,
    width,
    height,
    errorClass,
    showAsterisk,
    readOnly = false,
    shouldUpdateNumberOnScroll = true, // default behavior of "number" type field
    ...inputProps
}) => {
    // removing the default behavior of incrementing/decrementing number field on scroll
    const removeScroll = (event) => {
        const preventScroll = (e) => e.preventDefault();

        event.target.addEventListener('wheel', preventScroll, { passive: false });
        return () => {
            event.target.removeEventListener('wheel', preventScroll); // Cleanup listener
        };
    };

    useEffect(() => {
        if (!shouldUpdateNumberOnScroll && type === 'number' && id) {
            document.getElementById(id)?.addEventListener('focus', removeScroll);
        }

        return () => {
            if (!shouldUpdateNumberOnScroll && type === 'number' && id)
                document.getElementById(id)?.removeEventListener('focus', removeScroll); // Cleanup listener
        };
    }, [id, type]);

    return (
        <div>
            {label && type !== 'hidden' && (
                <label
                    className={`block w-full text-left text-gray-900 ${labelClass}`}
                >
                    {label}{subLabel && <span className={subLabelClass}> {subLabel}</span>}{showAsterisk ? <span className="text-semantic-error-base"> *</span> : ''}
                </label>
            )}
            <input
                {...register(id)}
                id={id}
                {...inputProps}
                type={type}
                onClick={onClick}
                onPaste={onPaste}
                className={`${error ? 'border-semantic-error-base' : 'border-gray-300'
                    } ${inputProps.border ? inputProps.border : 'border-1'} ${width ? width : 'w-full'} ${height ? height : 'h-12'
                    } px-4 py-3 rounded-lg mt-2 box-border ${inputProps.className}`}
                placeholder={placeholder}
                readOnly={readOnly}
            />
            {error && type !== 'hidden' && (
                <div className={`text-semantic-error-base text-left ${errorClass}`}>{error.message}</div>
            )}
        </div>
    );
};
export default Input;
