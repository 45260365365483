import { forwardRef } from 'react';
import Link from 'next/link';

const NextLink = forwardRef(({ ...property }, ref) => {
    const path = property?.href || '#';

    return path === '#' ? (
        <a
            onClick={property.onClick}
            className={`cursor-pointer text-${property.size} font-${property.weight}  ${property.className} `}
            target={property.target}
            id={property.id}
        >
            {property.children}
        </a>
    ) : (
        <Link href={path} legacyBehavior>
            <a
                onClick={property.onClick}
                className={`text-${property.size} font-${property.weight}  ${property.className} `}
                target={property.target}
                id={property.id}
            >
                {property.children}
            </a>
        </Link>
    );
});

export default NextLink;
