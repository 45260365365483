import React from 'react';
const Text = ({
    content,
    weight,
    size,
    isInnerHtml = false,
    htmlTag = 'div',
    ...property
}) => {
    const fontWeightClass = weight ? `font-${weight}` : '';
    const fontSizeClass = size ? `text-${size}` : '';
    const className =
        `${fontSizeClass} ${fontWeightClass} ${property.className} `.trim();
    const dataAnimation = property['data-animation'];
    const contentTag = React.createElement(
        htmlTag,
        {
            className: className,
            ...(dataAnimation && { 'data-animation': dataAnimation },
            isInnerHtml && {
                dangerouslySetInnerHTML: { __html: content },
            }),
        },
        !isInnerHtml ? content : null
    );
    return contentTag;
};
export default Text;